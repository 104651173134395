.lds-ripple {
  color: #1c4c5b; /* Matches the icon color */
}
.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  padding-right: 36px;
  padding-right: 36px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 22px;
    left: 22px;
    width: 4px;
    height: 4px;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    opacity: 0;
  }
}

.lds-ripple {
  width: 48px; /* Match the icon's size */
  height: 48px; /* Match the icon's size */
}
.lds-ripple div {
  border-width: 3px; /* Match visual weight */
}
