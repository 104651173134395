@tailwind base;
@tailwind components;
@tailwind utilities;

.searchBar {
  @apply flex items-center  p-2 xl:w-fit  rounded-lg;
}

.highlightContainer {
  @apply w-40 h-32 md:w-56 md:h-48  xl:w-72 xl:h-60  m-auto  flex flex-col justify-around items-center rounded-lg bg-white overflow-hidden;
}

.highlightTitle {
  @apply md:text-xl lg:text-2xl text-slate-400;
}

.highlightMain {
  @apply text-2xl md:text-4xl lg:text-5xl xl:text-6xl;
}

.highlightSub {
  @apply text-lg md:text-xl;
}

.highlightSmall {
  @apply text-sm md:text-lg;
}
.gradientButton {
  @apply flex items-center justify-center w-8 h-8 lg:w-16 lg:h-16 bg-gradient-to-b from-yellow-400 to-orange-400 rounded-full shadow-lg;
}

.arrowIcon {
  @apply w-6 h-6;
}
